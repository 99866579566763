import axiosInstance from "src/config/axiosInstance";

export const getAirportsList = async (input , config) => {

    const response = await axiosInstance.get(
        `${process.env.REACT_APP_HOST_API}/flights/location/${input}`,
        {
            ...config,
        },
    );
    return response.data;
};
