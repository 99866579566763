import { createContext } from "react";

/**
 * @typedef {Object} StripeContextProps
 * @property {null | string} stripeApiKey
 * @property {Promise<null | import("@stripe/stripe-js").Stripe>} stripePromise
 */
/**
 * @type {import("react").Context<StripeContextProps>}
 */
const StripeContext = createContext({});

export default StripeContext;
