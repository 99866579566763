import axios, { AxiosError, CanceledError } from "axios";
import qs from "qs";
import {
    HOST_API,
    HOST_API_CRUISES,
    HOST_API_FLIGHTS,
} from "src/config-global";

import { queryClient } from "src/tanstack/QueryClient";
import { storageKeys } from "src/utils";
import { enqueueSnackbar } from "notistack";

//timeout up to 3 minutes for hotelbeds and TBO requirements
const axiosInstance = axios.create({
    baseURL: HOST_API,
    timeout: 280000,
    withCredentials: true,
    headers: {
        "X-Client-TimeZone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
});

axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(new Error(error)),
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (axios.isCancel(error) || error instanceof CanceledError) {
            return Promise.resolve(null);
        }

        if (error instanceof AxiosError) {
            if (error.response?.status === 498) {
                if (error.response?.data?.error === "missing_authentication") {
                    return Promise.reject("No Session");
                }
                appLogout(error.response.data.message || "Session Expired");
                //router.push(paths.authDemo.modern.login + "?user-signup");

                return Promise.resolve("Session Expired");
            }
            if (error.response?.status === 402) {
                return Promise.reject(error.response);
            }
            return Promise.reject(
                error.response?.data?.message || "Something went wrong",
            );
        }

        return Promise.reject("Unknown Error");
    },
);

const flightAPI = axios.create({
    baseURL: HOST_API_FLIGHTS,
});

flightAPI.interceptors.response.use(
    (res) => res,
    (err) =>
        Promise.reject(
            (err.response && err.response.data) || "Something went wrong",
        ),
);

const cruiseAPI = axios.create({
    baseURL: HOST_API_CRUISES,
    paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
});

cruiseAPI.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem(storageKeys.TOKEN);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error),
);

cruiseAPI.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error instanceof CanceledError) {
            return Promise.reject(error);
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong",
        );
    },
);

function appLogout(message) {
    enqueueSnackbar(message, { variant: "error" });
    const queries = queryClient.getQueriesData();
    if (queries.length > 0) {
        queryClient.removeQueries({
            predicate: () => true,
        });
    }
    localStorage.removeItem(storageKeys.TOKEN);
    localStorage.removeItem(storageKeys.SUPERADMIN_TOKEN);
    sessionStorage.clear();
    delete axiosInstance.defaults.headers.common.Authorization;
    window.location.replace("/");
}

export { axiosInstance, flightAPI, cruiseAPI };
export default axiosInstance;
