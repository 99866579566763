import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import { RouterLink } from "src/routes/components";
import { forwardRef } from "react";
import { useResponsive } from "src/hooks/use-responsive";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const lgUp = useResponsive("up", "lg");
    const storedUser = sessionStorage.getItem("user");
    let companyLogo = "/assets/logotz.png"; // Correct variable name
    let logoWidth = 180;
    let isParentCompany = false;

    if (storedUser) {
        const { company: userCompany } = JSON.parse(
            sessionStorage.getItem("user"),
        );
        isParentCompany = userCompany?.isParent || false;

        if (isParentCompany) {
            companyLogo = userCompany?.avatarUrl || companyLogo;
        } else {
            companyLogo = userCompany?.avatarurlParent || companyLogo;
        }

        logoWidth = userCompany?.logoWidth || logoWidth;
    }
    // HDY AJUSTES EN TAMAÑO DEL LOGO DEL SIDEBAR
    const logo = (
        <Box
            ref={ref}
            component="img"
            src={companyLogo}
            //className="logo-inverted"
            alt="Logo"
            sx={{
                width: parseInt(logoWidth),
                height: "auto",
                ...(lgUp && { maxHeight: 40 }),
                display: "inline-flex",
                cursor: "pointer",
                objectFit: "contain",
                ...sx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        // HDY CAMBIO RUTA DESTINO AL HACER CLICK EN LOGO
        <Link
            component={RouterLink}
            href="/dashboard/home"
            sx={{ display: "contents" }}
        >
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;
