import {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from "react";
import PropTypes from "prop-types";
import { queryClient } from "src/tanstack/QueryClient";
import { AuthContext } from "./auth-context";
import { axiosInstance } from "src/config/axiosInstance";
import { endpoints } from "src/utils/axios";
import { storageKeys } from "src/utils";
import { useLocales } from "src/locales";
import { initialState } from "./auth-reducer-state";
// import { getWorkerPort } from "../../worker/worker-loader";
import { usePathname, useRouter } from "src/routes/hooks";
import { PATH_AFTER_LOGIN } from "src/config-global";
import { paths } from "src/routes/paths";
import { useSnackbar } from "src/components/snackbar";

const ACTION_TYPES = {
    INITIAL: "INITIAL",
    LOGIN: "LOGIN",
    REGISTER: "REGISTER",
    LOGOUT: "LOGOUT",
    NO_SESSION: "NO-SESSION",
    USER_PARTIAL: "USER-PARTIAL",
    UPDATED_HAS_CROSS_PROJECTS: "UPDATED_HAS_CROSS_PROJECTS",
    UPDATED_HAS_COMPANY_TRAVEL_POLICIES: "UPDATED_HAS_COMPANY_TRAVEL_POLICIES",
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.INITIAL:
            return {
                loading: false,
                user: action.payload.user,
                /*  role: action.payload.role, */ // Set the user's role during initialization
                paymentMethods: action.payload.paymentMethods,
                isValidSubscription: action.payload.isValidSubscription,
                travelPolicies: action.payload.travelPolicies,
                hasLoyaltyCards: action.payload.hasLoyaltyCards || false,
                hasCompanyCrossProjects:
                    action.payload.hasCompanyCrossProjects || false,
                hasCompanyTravelPolicies:
                    action.payload.hasCompanyTravelPolicies || false,
            };
        case ACTION_TYPES.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                paymentMethods: action.payload.paymentMethods,
                isValidSubscription: action.payload.isValidSubscription,
                travelPolicies: action.payload.travelPolicies,
                hasLoyaltyCards: action.payload.hasLoyaltyCards || false,
                hasCompanyCrossProjects:
                    action.payload.hasCompanyCrossProjects || false,
                hasCompanyTravelPolicies:
                    action.payload.hasCompanyTravelPolicies || false,
            };
        case ACTION_TYPES.REGISTER:
            return {
                ...state,
                user: action.payload.user,
            };
        case ACTION_TYPES.LOGOUT:
        case ACTION_TYPES.NO_SESSION:
            return {
                ...state,
                loading: false,
                user: null,
                paymentMethods: [],
                isValidSubscription: false,
                travelPolicies: null,
                hasLoyaltyCards: false,
                hasCompanyCrossProjects: false,
                hasCompanyTravelPolicies: false,
            };
        case ACTION_TYPES.USER_PARTIAL:
            return {
                ...state,
                user: { ...state.user, ...action.payload.user },
            };
        case ACTION_TYPES.UPDATED_HAS_CROSS_PROJECTS:
            return {
                ...state,
                hasCompanyCrossProjects:
                    action.payload.hasCompanyCrossProjects || false,
            };
        case ACTION_TYPES.UPDATED_HAS_COMPANY_TRAVEL_POLICIES:
            return {
                ...state,
                hasCompanyTravelPolicies:
                    action.payload.hasCompanyTravelPolicies || false,
            };
        default:
            return state;
    }
};

export function AuthProvider({ children }) {
    const { t, setClientLang } = useLocales();
    const { enqueueSnackbar } = useSnackbar();
    const [state, dispatch] = useReducer(reducer, initialState);
    const isFetchingSession = useRef(false);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const pathname = usePathname();

    const router = useRouter();

    const setSessionOnLogin = useCallback(
        ({
            userSession,
            setLang = true,
            hasLoyaltyCards = false,
            hasCompanyCrossProjects = false,
        }) => {
            const { company } = userSession || {};
            sessionStorage.setItem(
                storageKeys.USER,
                JSON.stringify({
                    ...userSession,
                    token: null,
                    superAdminToken: null,
                }),
            );
            sessionStorage.setItem(storageKeys.USER_EMAIL, userSession?.email);
            sessionStorage.setItem(
                storageKeys.USER_NAME,
                userSession?.userName,
            );
            sessionStorage.setItem(
                storageKeys.USER_FIRST_NAME,
                userSession?.firstName,
            );
            sessionStorage.setItem(
                storageKeys.USER_LAST_NAME,
                userSession?.lastName,
            );
            sessionStorage.setItem(storageKeys.TOKEN, userSession.token);
            if (userSession.superAdminToken) {
                sessionStorage.setItem(
                    storageKeys.SUPERADMIN_TOKEN,
                    userSession.superAdminToken,
                );
            }
            if (userSession.country) {
                sessionStorage.setItem(
                    storageKeys.USER_COUNTRY,
                    userSession.country,
                );
            }
            sessionStorage.setItem(storageKeys.USER_NAME, userSession?.name);
            sessionStorage.setItem(storageKeys.USER_ID, userSession?.userId);
            sessionStorage.setItem(
                storageKeys.USER_CITY,
                userSession?.userCity,
            );
            sessionStorage.setItem(storageKeys.USER_LAT, userSession?.userLat);
            sessionStorage.setItem(
                storageKeys.USER_LONG,
                userSession?.userLong,
            );
            sessionStorage.setItem(
                storageKeys.USER_ROLE,
                userSession?.userRole,
            );
            sessionStorage.setItem(storageKeys.COMPANY_ID, company?.companyId);
            sessionStorage.setItem(storageKeys.CURRENCY, userSession?.currency);
            sessionStorage.setItem(storageKeys.LOGO, company?.avatarUrl);
            sessionStorage.setItem(
                storageKeys.COMPANY_NAME,
                company?.companyName,
            );
            sessionStorage.setItem(storageKeys.COMPANY_EMAIL, company?.email);
            sessionStorage.setItem(storageKeys.COMPANY_VAT, company?.vat);
            sessionStorage.setItem(storageKeys.BANK_NAME, company?.bankName);
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT,
                company?.bankAccount,
            );
            sessionStorage.setItem(storageKeys.SWIFT, company?.swift);
            //sessionStorage.setItem(storageKeys.EMAIL, company?.email);

            // if (setLang) {
            setClientLang(userSession?.language || "English");
            // }
            sessionStorage.setItem(
                storageKeys.BANK_ACCOUNT_OWNER,
                company?.bankAccountOwner,
            );
            sessionStorage.setItem(
                storageKeys.COMPANY_PARENT_NAME,
                company?.companyParentName,
            );
            sessionStorage.setItem(
                storageKeys.ONBOARDING_STATUS,
                company?.onboardingStatus,
            );
            sessionStorage.setItem(
                storageKeys.APPLY_TAX,
                userSession?.applicableTax,
            );
            sessionStorage.setItem(
                storageKeys.SUBSCRIPTION_PLAN,
                userSession?.subscriptionPlan,
            );
            sessionStorage.setItem(
                storageKeys.PAYMENT_METHODS,
                userSession?.paymentMethods,
            );
            sessionStorage.setItem(
                storageKeys.HOTELS_FEE,
                userSession?.feesHotels,
            );
            sessionStorage.setItem(
                storageKeys.ACTIVITIES_FEE,
                userSession?.fees.activitiesFee,
            );
            sessionStorage.setItem(
                storageKeys.TRANSFERS_FEE,
                userSession?.fees.transfersFee,
            );
            sessionStorage.setItem(storageKeys.CARS_FEE, userSession?.feesCars);
            sessionStorage.setItem(
                storageKeys.FLIGTHS_DOMESTIC_FEE,
                userSession?.fees.flightsDomesticFee,
            );
            sessionStorage.setItem(
                storageKeys.FLIGTHS_INTERNATIONAL_FEE,
                userSession?.fees.flightsInternationalFee,
            );
            sessionStorage.setItem(
                storageKeys.CRUISES_FEE,
                userSession?.fees.CruisesFee,
            );
            sessionStorage.setItem(
                storageKeys.PACKAGE_FEE,
                userSession?.fees.packagesFee,
            );

            sessionStorage.setItem(
                storageKeys.HAS_LOYALTY_CARDS,
                hasLoyaltyCards,
            );

            localStorage.setItem(
                storageKeys.SHOW_CUSTOME_REDIRECT_PROJECTS,
                !hasCompanyCrossProjects,
            );
        },
        [setClientLang],
    );
    const invalidateQueries = useCallback(() => {
        const queries = queryClient.getQueriesData();
        if (queries.length === 0) return;
        queryClient.removeQueries({
            predicate: () => true,
        });
    }, []);

    const initialize = useCallback(async () => {
        if (isFetchingSession.current) {
            return;
        }
        isFetchingSession.current = true;
        try {
            const response = await axiosInstance.get(endpoints.auth.me);
            if (!response.data?.userSession) {
                throw new Error("Session Expired");
            }
            const {
                userSession,
                paymentMethods,
                isValidSubscription,
                travelPolicies,
                hasLoyaltyCards,
                hasCompanyCrossProjects,
                hasCompanyTravelPolicies,
            } = response.data;
            sessionStorage.setItem(storageKeys.TOKEN, userSession.token);
            setSessionOnLogin({
                userSession,
                setLang: false,
                hasCompanyCrossProjects,
                hasLoyaltyCards,
            });

            dispatch({
                type: ACTION_TYPES.INITIAL,
                payload: {
                    user: userSession,
                    paymentMethods,
                    isValidSubscription,
                    travelPolicies,
                    hasLoyaltyCards,
                    hasCompanyCrossProjects,
                    hasCompanyTravelPolicies,
                },
            });
            ///VERY IMPORTANT PLEASE DO NOT DELETE THE user-signup label PORQUE SIRVE PARA TRACKEAR CONVERSIONES
            if (pathname.includes("/business-travel/auth/")) {
                router.push(paths.dashboard.home.root + "?user-signup");
            }
            isFetchingSession.current = false;
            return;
        } catch (error) {
            // console.error("Error en la inicialización:", error);
        }
        sessionStorage.clear();
        dispatch({
            type: ACTION_TYPES.NO_SESSION,
        });
        isFetchingSession.current = false;
    }, [router, invalidateQueries]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    const signIn = useCallback(
        async (responseData, redirect = true) => {
            try {
                const {
                    userSession,
                    isValidSubscription,
                    paymentMethods,
                    travelPolicies,
                    hasLoyaltyCards,
                    hasCompanyCrossProjects,
                    hasCompanyTravelPolicies,
                } = responseData;

                setSessionOnLogin({
                    userSession,
                    hasCompanyCrossProjects,
                    hasLoyaltyCards,
                });

                dispatch({
                    type: ACTION_TYPES.LOGIN,
                    payload: {
                        user: userSession,
                        isValidSubscription,
                        paymentMethods,
                        travelPolicies,
                        hasLoyaltyCards,
                        hasCompanyCrossProjects,
                        hasCompanyTravelPolicies,
                    },
                });

                if (redirect) {
                    router.push(paths.dashboard.travelPreferences);
                }
            } catch (error) {
                console.error("Error en inicio de sesión:", error);
            }
        },
        [router, setSessionOnLogin],
    );
    const fetchLogin = useCallback(
        async (email, password, forceLogin = false, keepSession = false) => {
            try {
                const params = {};
                if (forceLogin) {
                    params.forceLogin = true;
                }
                if (keepSession) {
                    params.keepSession = true;
                }
                const response = await axiosInstance.post(
                    endpoints.auth.login,
                    {
                        email,
                        password,
                    },
                    { params },
                );

                signIn(response.data, false);

                return { status: "SUCCESS" };
            } catch (error) {
                // if (error?.loginDate) {
                //     return {
                //         status: "ACTIVE_SESSION",
                //         responseData: {
                //             loginDate: error.loginDate,
                //             device: error.device,
                //         },
                //     };
                // }
                if (error === "confirm-email") {
                    return { status: "USER_EMAIL_NOT_CONFIRMED" };
                }
                if (error === "bad_provider") {
                    return { status: "USER_USE_OTHER_PROVIDER" };
                }
                return { status: "ERROR" };
            }
        },
        [signIn],
    );

    const updateHasCrossProjects = useCallback((value) => {
        dispatch({
            type: ACTION_TYPES.UPDATED_HAS_CROSS_PROJECTS,
            payload: {
                hasCompanyCrossProjects: value,
            },
        });
    }, []);

    const updateHasCompanyTravelPolicies = useCallback((value) => {
        dispatch({
            type: ACTION_TYPES.UPDATED_HAS_COMPANY_TRAVEL_POLICIES,
            payload: {
                hasCompanyTravelPolicies: value,
            },
        });
    }, []);

    const adminLogin = useCallback(
        async (userId) => {
            try {
                const response = await axiosInstance.post(
                    endpoints.users.adminLogin(userId),
                );
                const {
                    userSession,
                    isValidSubscription,
                    paymentMethods,
                    travelPolicies,
                    hasLoyaltyCards,
                    hasCompanyCrossProjects,
                    hasCompanyTravelPolicies,
                } = response.data;
                setSessionOnLogin({
                    userSession,
                    hasCompanyCrossProjects,
                    hasLoyaltyCards,
                });

                dispatch({
                    type: ACTION_TYPES.LOGIN,
                    payload: {
                        user: userSession,
                        isValidSubscription,
                        paymentMethods,
                        travelPolicies,
                        hasCompanyTravelPolicies,
                    },
                });
                return true;
            } catch (error) {
                console.error("Error en adminLogin:", error);
                return false;
            }
        },
        [setSessionOnLogin],
    );

    const register = useCallback(
        async (
            userSession,
            isValidSubscription,
            paymentMethods,
            travelPolicies,
        ) => {
            try {
                setSessionOnLogin({ userSession });

                dispatch({
                    type: ACTION_TYPES.LOGIN,
                    payload: {
                        user: userSession,
                        isValidSubscription,
                        paymentMethods,
                        travelPolicies,
                    },
                });

                router.push(paths.dashboard.travelPreferences);
            } catch (error) {
                console.error("Error en registro:", error);
            }
        },
        [router, setSessionOnLogin],
    );

    const logout = useCallback(async () => {
        const keepSessionSelection = localStorage.getItem(
            storageKeys.KEEP_SESSION_ACTIVE,
        );
        const shoswCustomRedirectAdviceProjects = localStorage.getItem(
            storageKeys.SHOW_CUSTOME_REDIRECT_PROJECTS,
        );
        const hasLoyaltyCards = localStorage.getItem(
            storageKeys.HAS_LOYALTY_CARDS,
        );
        try {
            const maxRetries = 3;
            let retries = 0;
            while (retries < maxRetries) {
                try {
                    await axiosInstance.post(endpoints.auth.logout);
                    break;
                } catch (error) {
                    retries++;
                    if (retries === maxRetries) {
                        throw error;
                    }
                    await new Promise((resolve) =>
                        setTimeout(() => resolve(true), 1000),
                    );
                }
            }
            // Limpio local y session storage después del logout para poder cerrar correctamente la sesión
            invalidateQueries();
            sessionStorage.clear();
            localStorage.clear();
            if (keepSessionSelection) {
                localStorage.setItem(storageKeys.KEEP_SESSION_ACTIVE, "true");
            }
            if (shoswCustomRedirectAdviceProjects) {
                localStorage.setItem(
                    storageKeys.SHOW_CUSTOME_REDIRECT_PROJECTS,
                    "true",
                );
            }
            if (hasLoyaltyCards) {
                localStorage.setItem(storageKeys.HAS_LOYALTY_CARDS, "true");
            }
            sessionStorage.clear();
            router.replace("/");
            dispatch({
                type: ACTION_TYPES.LOGOUT,
            });
            return true;
        } catch (err) {
            console.error("Error en logout:", err);
            enqueueSnackbar(t("Something went wrong"), { variant: "error" });
            return false;
        }
    }, [invalidateQueries, enqueueSnackbar, router, dispatch, t]);

    const updateUserState = useCallback(
        (partialUser) => {
            dispatch({
                type: ACTION_TYPES.USER_PARTIAL,
                payload: {
                    user: partialUser,
                },
            });
            sessionStorage.setItem(
                storageKeys.USER,
                JSON.stringify({
                    ...state.user,
                    ...partialUser,
                }),
            );
        },
        [state],
    );

    const status = state.loading
        ? "loading"
        : state.user
          ? "authenticated"
          : "unauthenticated";

    const memoizedValue = useMemo(
        () => ({
            user: state.user,
            paymentMethods: state.paymentMethods,
            isValidSubscription: state.isValidSubscription,
            method: "jwt",
            loading: status === "loading",
            authenticated: status === "authenticated",
            unauthenticated: status === "unauthenticated",
            //
            fetchLogin,
            signIn,
            adminLogin,
            updateHasCrossProjects,
            register,
            logout,
            updateUserState,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
            travelPolicies: state.travelPolicies,
            hasLoyaltyCards: state.hasLoyaltyCards,
            hasCompanyCrossProjects: state.hasCompanyCrossProjects,
            hasCompanyTravelPolicies: state.hasCompanyTravelPolicies,
            updateHasCompanyTravelPolicies,
        }),
        [
            fetchLogin,
            signIn,
            adminLogin,
            logout,
            register,
            updateHasCrossProjects,
            updateUserState,
            state.user,
            state.paymentMethods,
            state.isValidSubscription,
            status,
            paymentMethod,
            setPaymentMethod,
            userInfo,
            setUserInfo,
            state.travelPolicies,
            state.hasLoyaltyCards,
            state.hasCompanyCrossProjects,
            state.hasCompanyTravelPolicies,
            updateHasCompanyTravelPolicies,
        ],
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
