import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import { RouterLink } from "src/routes/components";
import { forwardRef } from "react";
import { useSearchParams } from "src/routes/hooks";
import { UsersService } from "src/api/tz";
import React, { useEffect, useState } from "react";

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    //LOGIN page shows logo of the company login
    const searchParams = useSearchParams();
    const { fetchUserLoginDetails } = UsersService;

    const companyId = searchParams.get("company"); // Get the company parameter
    const [currentCompanyLogo, setCurrentCompanyLogo] = useState(null);
    const [logoToShow, setLogoToShow] = useState("/assets/LOGO_NEGRO.png");
    const [logoWidth, setLogoWidth] = useState(180);

    const getCompanyDetails = async () => {
        try {
            const response = await fetchUserLoginDetails(companyId, 1, 1000);

            if (response?.data) {
                setCurrentCompanyLogo(response?.data?.avatarUrl);
                setLogoToShow(response?.data?.avatarUrl);
                setLogoWidth(response?.data?.logoWidth || logoWidth);
            } else {
                setLogoToShow("/assets/LOGO_NEGRO.png");
            }
        } catch (error) {
            console.error("Error fetching company details:", error);
            setLogoToShow("/assets/LOGO_NEGRO.png"); // Fallback to default logo in case of an error
        }
    };

    useEffect(() => {
        if (companyId) {
            getCompanyDetails();
        }
    }, [companyId]);

    const logo = (
        <Box
            ref={ref}
            component="img"
            src={logoToShow}
            alt="Logo"
            sx={{
                width: 150,
                height: "auto",
                display: "inline-flex",
                cursor: "pointer",
                ...sx,
            }}
            {...other}
        />
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link
            component={RouterLink}
            href="/dashboard/home"
            sx={{ display: "contents" }}
        >
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;
