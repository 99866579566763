import React, { useCallback, useState, useEffect } from "react";
import { useLocales } from "src/locales";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { debounce } from "@mui/material/utils";
import { useGoogleMapsPlacesContext } from "./useGoogleMapsPlacesContext";

export function AutoCompleteGooglePlaces({
    cityData,
    handleChange,
    label,
    placeholder,
    language = "en", // Default language is English
    textFieldSx,
    textFieldExtraProps = null,
}) {
    const { t } = useLocales();
    const [locationOptions, setLocationOptions] = useState([]); // Array for storing options per city
    const { fetchPlaceSuggestions, getPlaceById } =
        useGoogleMapsPlacesContext();

    // ✅ Ensure cityData is used as the initial value
    const [selectedValue, setSelectedValue] = useState(cityData || null);

    useEffect(() => {
        setSelectedValue(cityData || null);
    }, [cityData]);

    const onInputChange = useCallback(
        debounce(async (input) => {
            if (!input) return;
            try {
                const predictions = await fetchPlaceSuggestions(
                    input,
                    language,
                );
                const formattedOptions = predictions.map((place) => ({
                    label: place.description,
                    cityLabel:
                        place.structured_formatting?.secondary_text ?? "",
                    placeId: place.place_id,
                }));
                setLocationOptions(formattedOptions);
            } catch (error) {
                console.error("Error fetchPlaceSuggestions", error);
            }
        }, 300),
        [fetchPlaceSuggestions, language],
    );

    const handlePlaceChange = useCallback(
        async (value) => {
            setSelectedValue(value || null); // ✅ Ensure selectedValue is updated

            if (value) {
                try {
                    const placeData = await getPlaceById(
                        value.placeId,
                        value.label,
                        language,
                    );
                    const newValue = {
                        label: value.label,
                        cityLabel: value.cityLabel,
                        name: placeData.city,
                        city: placeData.city,
                        country: placeData.country,
                        countryCode: placeData.countryCode,
                        latitude: placeData.latitude,
                        longitude: placeData.longitude,
                        isAddress: placeData.isAddress,
                        placeId: value.placeId,
                        adressName: placeData.adressName,
                    };
                    handleChange(newValue);
                } catch (error) {
                    console.error("Error", error);
                }
            } else {
                handleChange(null);
            }
        },
        [handleChange, language],
    );

    const parseOptionLabel = useCallback((option) => {
        if (!option) return "";
        if (option.label) return option.label;
        return `${option.city || option.name}, ${option.country}`;
    }, []);

    return (
        <Box sx={{ minWidth: "100%", margin: "auto" }}>
            <Autocomplete
                id="city-search"
                getOptionLabel={(option) => parseOptionLabel(option)}
                options={locationOptions} // Use the options specific to this city
                fullWidth
                autoComplete
                onInputChange={(event, input) => onInputChange(input)}
                onChange={(_, value) => handlePlaceChange(value)}
                value={selectedValue} // ✅ Use value instead of defaultValue
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        fullWidth
                        placeholder={placeholder}
                        sx={textFieldSx}
                        {...textFieldExtraProps}
                    />
                )}
            />
        </Box>
    );
}
