import { Elements } from "@stripe/react-stripe-js";
import { useStripeContext } from "./useStripeContext";

export function StripeElementsProvider({ children, options }) {
    const { stripePromise } = useStripeContext();
    return (
        <Elements stripe={stripePromise} options={options}>
            {children}
        </Elements>
    );
}
