// @mui
import Button from "src/components/ui/button";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { paths } from "src/routes/paths";
import { usePathname } from "src/routes/hooks";
import { RouterLink } from "src/routes/components";
// _mock
import { _socials } from "src/_mock";
// components
import Logo from "src/components/logo";
import { useLocales } from "src/locales";
// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: "TRAVELANDZ",
        children: [
            { name: "Home", href: "/" },
            { name: "About us", href: paths.about },
            { name: "Contact us", href: paths.contact },
            {
                name: "Travel Software for Travel Agencies",
                href: "https://travelandz.com",
            },
            { name: "Sign Up", href: paths.authDemo.modern.register },
            { name: "Login", href: paths.authDemo.modern.login },

            // { name: 'FAQs', href: paths.faqs },
        ],
    },
    {
        headline: "Legal",
        children: [
            { name: "Terms and Conditions", href: "/terms" },
            { name: "Privacy Policy", href: "/privacy" },
        ],
    },
    {
        headline: "Contact",
        children: [
            { name: "hello@travelandz.com", href: paths.contact },
            { name: "AI Travel Software Team", href: paths.about },
        ],
    },
];

// ----------------------------------------------------------------------

export default function Footer() {
    const { t } = useLocales();

    const mainFooter = (
        <Box
            component="footer"
            sx={{
                position: "relative",
                bgcolor: "background.default",
            }}
        >
            <Divider />

            <Container
                sx={{
                    pt: 10,
                    textAlign: { xs: "center", md: "unset" },
                }}
            >
                <Grid
                    container
                    justifyContent={{
                        xs: "center",
                        md: "space-between",
                    }}
                >
                    <Grid xs={8} md={3} sx={{ mb: 5 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                maxWidth: 270,
                                mx: { xs: "auto", md: "unset" },
                                mb: 5,
                            }}
                        >
                            {t(
                                "At Travelandz, we’re transforming the travel industry with cutting-edge AI software designed to enhance the travel experience for small and medium-sized enterprises (SMEs). Our intelligent solutions streamline travel management, optimize costs, and provide personalized options to empower travelers and businesses alike. Together, we can make every journey more efficient and impactful, ensuring your travel needs are met with innovation and care.",
                            )}
                        </Typography>

                        <Logo />
                    </Grid>

                    <Grid xs={12} md={8}>
                        <Stack
                            spacing={5}
                            direction={{ xs: "column", md: "row" }}
                        >
                            {LINKS.map((list) => (
                                <Stack
                                    key={list.headline}
                                    spacing={2}
                                    alignItems={{
                                        xs: "center",
                                        md: "flex-start",
                                    }}
                                    sx={{ width: 1 }}
                                >
                                    <Typography
                                        component="div"
                                        variant="overline"
                                    >
                                        {t(list.headline)}
                                    </Typography>

                                    {list.children.map((link) => (
                                        <Link
                                            key={link.name}
                                            component={RouterLink}
                                            href={link.href}
                                            color="inherit"
                                            variant="body2"
                                        >
                                            {t(link.name)}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column", // Align items in a vertical column
                                    justifyContent: "flex-start", // Aligns to the top vertically
                                }}
                            >
                                <Button
                                    color="inherit"
                                    variant="default"
                                    href="https://calendly.com/mariajosejorda/travelandz"
                                >
                                    {t("Request a Demo")}
                                </Button>
                                {/* You can add more content below the button */}
                            </div>
                        </Stack>
                    </Grid>
                </Grid>

                <Typography variant="body2" sx={{ mt: 2, mb: 5 }}>
                    © {new Date().getFullYear()}{" "}
                    {t("All rights reserved by Travelandz")}
                </Typography>
            </Container>
        </Box>
    );

    return mainFooter;
}
