import React from "react";
import { Link } from "react-router-dom";
import Logo from "src/components/logo-saas";
import Button from "src/components/ui/button";
import { useLocales } from "src/locales";
import { paths } from "src/routes/paths";

const Header = () => {
    const { t } = useLocales();

    return (
        <header className="fixed top-0 left-0 w-full bg-gray-50 border-b border-gray-300 shadow-sm px-4 md:px-8 z-[999]">
            <div className="container mx-auto flex items-center justify-between py-2 ">
                {/* LOGO */}
                <Logo />

                {/* NAVIGATION LINKS (Hidden on small screens) */}
                <nav className="hidden md:flex space-x-6">
                    {[t("Contact Us"), t("About Us")].map((item, index) => (
                        <Link
                            key={index}
                            to={`/${item.toLowerCase().replace(" ", "-")}`}
                            className="text-lg font-medium text-gray-900 hover:text-blue-600 transition"
                        >
                            {item}
                        </Link>
                    ))}
                    <Link
                        key={4}
                        to={`https://travelandz.com/home2`}
                        className="text-lg font-medium text-gray-900 hover:text-blue-600 transition"
                    >
                        {t("Travel Agencies")}
                    </Link>
                </nav>

                {/* BUTTONS */}
                <div className="flex space-x-4 mb-2">
                    <Button
                        data-testId="login-navbar-button"
                        variant="outline"
                        className="hidden md:block" // This hides the button on mobile and shows it on md and larger screens
                        href={paths.authDemo.modern.login}
                    >
                        {t("Login")}
                    </Button>

                    <Button
                        data-testId="sign-up-navbar-button"
                        variant="default"
                        href={paths.authDemo.modern.register}
                        sx={{ pb: { xs: 2, md: 0 } }}
                    >
                        {t("Sign Up for Free")}
                    </Button>
                </div>
            </div>
        </header>
    );
};

export default Header;
