import { axiosInstance } from "src/config/axiosInstance";

class UsersService {
    static async fetchUsers() {
        const res = await axiosInstance.get("/users");
        return res.data;
    }
    static async fetchUserLogs(userId, filters) {
        const searchParams = new URLSearchParams();
        if (filters.from) {
            searchParams.append("from", new Date(filters.from).toISOString());
        }
        if (filters.to) {
            searchParams.append("to", new Date(filters.to).toISOString());
        }
        const res = await axiosInstance.get("/users/log-actions/" + userId, {
            params: searchParams,
        });
        return res.data;
    }
    static async fetchUsersSummary(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/summary/${companyId}`,
                );

                return response;
            } catch (error) {
                console.error("An error occurred:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async fetchUserDetails(projectId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/user/${projectId}`,
                );

                return response;
            } catch (error) {
                console.error("Error fetching project details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async UpdateUser(userId, body, maxRetries = 3, retryDelay = 1000) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.put(
                    `/users/updateUser/${userId}`,
                    body,
                );
                return response;
            } catch (error) {
                console.error("Error updating user:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        throw new Error("Max retries reached while updating user");
    }

    static async DeleteUserCompany(userId) {
        try {
            const response = await axiosInstance.delete(
                `/users/delete/${userId}`,
            );

            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async CreateUser(data) {
        try {
            const response = await axiosInstance.post(`/users/new`, data);

            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    static async verifyUserEmail(email) {
        try {
            const response = await axiosInstance.get(
                `/users/email/validate?email=${email}`,
            );

            return response?.data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    static async fetchUserLoginDetails(companyId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/users/login/${companyId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching company logo details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createUserAndCompanyByCIF(data) {
        console.log("Creating company with data:", data);
        try {
            const response = await axiosInstance.post(
                "/users/registerUserAndCompany",
                data,
            );
            return response.data;
        } catch (error) {
            console.error("Error creating company:", error);
        }
    }

    static async createUserAndCompanyByID(data) {
        const response = await axiosInstance.post(
            "/users/registerUserAndCompany",
            data,
        );
        return response?.data;
    }

    static async setCustomerStripeId(userId, paymentMethodId) {
        try {
            await axiosInstance.put(`/users/setCustomerStripeId/${userId}`, {
                paymentMethodId,
            });
        } catch (error) {
            console.error("Error setting stripe id:", error);
            throw error;
        }
    }

    static async updateUserCheckoutData(body) {
        try {
            await axiosInstance.put(`/users/updateCheckoutData`, body);
        } catch (error) {
            console.error("Error updating user checkout data:", error);
            throw error;
        }
    }

    static async FlightPreferences(userId) {
        try {
            const response = await axiosInstance.get(
                `/users/flightPreferences/${userId}`,
            );

            return response.data;
        } catch (error) {
            console.error("Error fetching flight preferences:", error);
        }
    }

    static async getBookedHotels(userId, cities) {
        try {
            const response = await axiosInstance.post(
                `/users/hotelPreferences/${userId}`,
                { cities },
            );

            return response.data;
        } catch (error) {
            console.error("Error fetching booked hotels:", error);
        }
    }

    static async getUserCheckoutData(users) {
        try {
            const response = await axiosInstance.post(`/users/checkoutData`, {
                users,
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching user checkout data:", error);
        }
    }

    static async TripPreferences() {
        try {
            const response = await axiosInstance.get(`/users/trip/preferences`);

            return response.data;
        } catch (error) {
            console.error("Error fetching trip preferences:", error);
        }
    }

    static async updateTripPreferences(userId, body) {
        try {
            const response = await axiosInstance.patch(
                `/users/trip/preferences/${userId}`,
                body,
            );

            return response.data;
        } catch (error) {
            console.error("Error updating trip preferences:", error);
            throw error;
        }
    }

    static async checkCompanyForSignUp(params) {
        try {
            const response = await axiosInstance.get(
                `/users/signUp/checkCompany`,
                { params },
            );

            return response.data;
        } catch (error) {
            console.warn("Warn - checking company for sign up");
            throw error;
        }
    }
    static async checkUserIDForSignUp(params) {
        try {
            const response = await axiosInstance.get(
                `/users/signUp/checkUserId`,
                { params },
            );

            return response.data;
        } catch (error) {
            console.warn("Warn - checking user id for sign up");
            throw error;
        }
    }

    static async createUsersFromCSV(usersData, companyId) {
        try {
            const response = await axiosInstance.post(
                `/users/createUsers/csv/${companyId}`,
                { usersData },
            );

            return response.data;
        } catch (error) {
            console.error("Error creating users from CSV:", error);
            throw error;
        }
    }
}

export default UsersService;
