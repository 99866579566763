import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { useMemo } from "react";
import { useAuthContext } from "src/auth/hooks";
import { iconifyIcon, ICONS } from "src/constants";
import { baseAdminItems, approverItems, superAdminItems } from "src/constants";
import { textGradient } from "src/theme/css";

export function useNavData() {
    const { t } = useLocales();
    const { user, hasCompanyTravelPolicies } = useAuthContext();

    const role = useMemo(() => user?.role || user?.userRole, [user]);

    const validateItems = (items) => {
        if (hasCompanyTravelPolicies) return items;

        return items.filter((item) => item.title !== "Approvals");
    };

    const showOptions = useMemo(
        () =>
            role === "superadmin" ||
            // (user?.company?.isParent && role === "business_admin") ||
            role === "business_admin" ||
            role === "traveler_admin" ||
            role === "approver",
        [role],
    );

    const itemsValidated = useMemo(() => {
        // if (role === "approver" && showOptions)
        //     return validateItems(approverItems);

        if (role === "business_admin" && showOptions)
            return validateItems([...approverItems, ...baseAdminItems]);

        if (role === "superadmin" && showOptions)
            return validateItems([
                ...approverItems,
                ...baseAdminItems,
                ...superAdminItems,
            ]);

        const baseUserItems = validateItems(approverItems);

        return baseUserItems;
    }, [role, showOptions, hasCompanyTravelPolicies]);

    const data = useMemo(
        () => [
            {
                items: [
                    {
                        title: t("Home"),
                        path: paths.dashboard.home.root,
                        icon: ICONS.label,
                    },
                ],
            },

            // Main Menu
            // ----------------------------------------------------------------------
            {
                items: [
                    // // MAIL

                    // PROJECT
                    {
                        title: (
                            <strong style={{ fontSize: 14 }}>
                                {t("Travel Preferences")}
                            </strong>
                        ),
                        path: paths.dashboard.travelPreferences,
                        icon: iconifyIcon("carbon:flight-international"),
                    },
                    {
                        title: (
                            <strong style={{ fontSize: 14 }}>
                                {t("AI Business Trip Planner")}
                            </strong>
                        ),
                        path: paths.dashboard.project.list,
                        icon: ICONS.ai,
                        sx: {
                            ...textGradient(
                                "300deg, #00FFFF, #007FFF, #00FFFF",
                            ),
                        },
                    },

                    /*{
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Flights")}
                            </strong>
                        ),
                        path: paths.dashboard.flight.home,
                        icon: ICONS.flight,
                    },
                    {
                        title: (
                            <strong style={{ color: "black" }}>
                                {t("Hotels")}
                            </strong>
                        ),
                        path: paths.dashboard.hotel.list,
                        icon: ICONS.hotel,
                    },*/

                    // {
                    //     title: (
                    //         <strong style={{ color: "black" }}>
                    //             {t("Transfers")}
                    //         </strong>
                    //     ),
                    //     path: paths.dashboard.transfer.home,
                    //     icon: ICONS.transfer,
                    // },

                    /*} {
                        title: "Bank Activity",
                        path: paths.dashboard.bankActivity.list,
                        icon: ICONS.booking,
                        children: [
                            {
                                title: "List",
                                path: paths.dashboard.bankActivity.list,
                                icon: ICONS.list,
                            },
                            {
                                title: "Reports",
                                path: paths.dashboard.bankActivity.report,
                                icon: ICONS.reports,
                            },
                        ],
                    },*/
                ],
            },
            showOptions && {
                subheader:
                    role === "superadmin" ? t("admin") : t("travelandz admin"),
                items: itemsValidated,
            },
        ],
        [t, role, showOptions, itemsValidated],
    );

    return data;
}
