import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import axiosInstance from "src/config/axiosInstance";
import { useLocales } from "src/locales";

export const GoogleMapsPlacesContext = createContext({});

const TZ_SPECIAL_KEY = process.env.REACT_APP_TZ_API_KEY;

export function GoogleMapsPlacesProvider({ children }) {
    const [apiKey, setApiKey] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const isFetching = useRef(false);
    const { currentLang } = useLocales();
    useEffect(() => {
        if (isFetching.current) return;
        const fetchMapsAPI_KEY = async () => {
            isFetching.current = true;
            try {
                const { data } = await axiosInstance.get(
                    "/integrations/google-places",
                    {
                        headers: {
                            "tz-api-key": TZ_SPECIAL_KEY,
                        },
                    },
                );
                setApiKey(data.apiKey);
            } catch (error) {
                console.error("Failed to fetch Google Maps API Key", error);
            }
            isFetching.current = false;
        };
        // get API
        fetchMapsAPI_KEY();
    }, []);
    const fetchPlaceSuggestions = useCallback(
        async (input) => {
            if (!window.google)
                throw new Error("Google Maps Script not loaded");
            const autocompleteService =
                new window.google.maps.places.AutocompleteService();
            const result = await autocompleteService.getPlacePredictions({
                input,
                types: ["geocode"],
                language: currentLang.value,
            });
            if (!result) throw new Error("Failed to fetch place suggestions");
            return result.predictions;
        },
        [currentLang],
    );

    const getPlaceById = useCallback(
        async (placeId, label) => {
            if (!window.google)
                throw new Error("Google Maps Script not loaded");
            const geocoder = new window.google.maps.Geocoder();

            try {
                const res = await geocoder.geocode({
                    placeId,
                    language: "en-US",
                });
                if (!res || !res.results || res.results.length === 0) {
                    throw new Error("Failed to fetch place details");
                }

                const results = res.results;
                const location = results[0].geometry.location;

                let city = "";
                let country = "";
                let countryCode = "";
                let isAddress = false;
                // Loop through results to find city (locality) and country
                results[0].address_components.forEach((comp) => {
                    if (
                        comp.types.includes("locality") ||
                        comp.types.includes("postal_town")
                    ) {
                        city = comp.long_name; // Locality (city) or similar
                    }
                    if (comp.types.includes("country")) {
                        country = comp.long_name; // Full country name
                        countryCode = comp.short_name; // ISO country code
                    }
                    // If no city found and it's an address (e.g., street_number or route), we might need to fallback
                    if (
                        comp.types.includes("street_number") ||
                        comp.types.includes("route")
                    ) {
                        isAddress = true;
                    }
                });
                if (!city) {
                    city = results[0].formatted_address.split(",")[0];
                }

                // Fallback to label for city if geocoding doesn't return a locality
                if (!city && label) {
                    city = label.split(", ")[0]; // Fallback to first part of label (before the comma)
                }

                // * Is not necessary to translate because geocoder.geocode.language is en-US
                /* if (
                    !city ||
                    !country ||
                    city !== city.toLowerCase() ||
                    country !== country.toLowerCase()
                ) {
                    try {
                        const { formattedAddress } = await translateToEnglish(
                            `${city}, ${country}`,
                            apiKey,
                        );
                        [translatedCity, translatedCountry] = formattedAddress
                            .split(", ")
                            .map((item) => item.trim());
                    } catch (translationError) {
                        console.warn(
                            "Translation failed, falling back to original values",
                            translationError,
                        );
                    }
                } */
                return {
                    city,
                    country,
                    countryCode, // Include country code
                    latitude: location.lat(),
                    longitude: location.lng(),
                    isAddress,
                    adressName:
                        results?.[0]?.address_components?.[0]?.long_name,
                };
            } catch (error) {
                console.error("Error in getPlaceById:", error);
                throw error;
            }
        },
        [apiKey],
    );

    return (
        <GoogleMapsPlacesContext.Provider
            value={{ isLoaded, fetchPlaceSuggestions, getPlaceById }}
        >
            {children}
            {apiKey && (
                <LoadMapsScript
                    libraries={["places"]}
                    setIsLoaded={setIsLoaded}
                    apiKey={apiKey}
                />
            )}
        </GoogleMapsPlacesContext.Provider>
    );
}

function LoadMapsScript({ libraries, setIsLoaded, apiKey }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: apiKey,
        libraries,
    });
    useEffect(() => {
        setIsLoaded(isLoaded);
    }, [isLoaded]);
    return null;
}
