import { Suspense, lazy } from "react";

import AuthModernCompactLayout from "src/layouts/auth/modern-compact";
import AuthModernLayout from "src/layouts/auth/modern";
import { Outlet } from "react-router-dom";
import { SplashScreen } from "src/components/loading-screen";
import { GoogleMapsPlacesProvider } from "src/components/google-maps-places";

const LoginModernPage = lazy(() => import("src/pages/auth-demo/modern/login"));
const RegisterModernPage = lazy(
    () => import("src/pages/auth-demo/modern/register"),
);
const RegisterTravelerModernPage = lazy(
    () => import("src/pages/auth-demo/modern/register-traveler"),
);
const ForgotPasswordModernPage = lazy(
    () => import("src/pages/auth-demo/modern/forgot-password"),
);
const VerifyModernPage = lazy(
    () => import("src/pages/auth-demo/modern/verify"),
);
const ResetPasswordModernPage = lazy(
    () => import("src/pages/auth-demo/modern/resetPassword"),
);
const NewPasswordModernPage = lazy(
    () => import("src/pages/auth-demo/modern/new-password"),
);

const authModern = {
    path: "auth",
    element: (
        <Suspense fallback={<SplashScreen />}>
            <Outlet />
        </Suspense>
    ),
    children: [
        {
            element: (
                <AuthModernLayout>
                    <Outlet />
                </AuthModernLayout>
            ),
            children: [
                {
                    path: "login",
                    element: <LoginModernPage />,
                },
                {
                    path: "register",
                    element: (
                        <GoogleMapsPlacesProvider>
                            <Outlet />
                        </GoogleMapsPlacesProvider>
                    ),
                    children: [
                        {
                            index: true,
                            element: <RegisterModernPage />,
                        },
                        {
                            path: "traveler",
                            element: <RegisterTravelerModernPage />,
                        },
                    ],
                },
            ],
        },

        {
            element: (
                <AuthModernCompactLayout>
                    <Outlet />
                </AuthModernCompactLayout>
            ),
            children: [
                {
                    path: "forgot-password",
                    element: <ForgotPasswordModernPage />,
                },
                { path: "new-password", element: <NewPasswordModernPage /> },
                { path: "verify/:token", element: <VerifyModernPage /> },
                {
                    path: "reset-password/:token",
                    element: <ResetPasswordModernPage />,
                },
            ],
        },
    ],
};

export const authDemoRoutes = [
    {
        path: "/business-travel",
        children: [authModern],
    },
];
