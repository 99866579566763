import { useContext } from "react";
import StripeContext from "./StripeContext";

export function useStripeContext() {
    const ctx = useContext(StripeContext);
    // const ctx = useContext(StripeContext);
    if (!ctx)
        throw new Error(
            "useStripeContext context must be use inside StripeContext.Provider",
        );

    return ctx;
}
