import PropTypes from "prop-types";
import { AnimatePresence, m } from "framer-motion";
// @mui
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
//
import Favicon from "../favicon";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocales } from "src/locales";

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, quotes = [], ...other }) {
    const { t } = useLocales();
    const [quote, setQuote] = useState(
        Array.isArray(quotes) && quotes.length > 0
            ? quotes[Math.floor(Math.random() * quotes.length)]
            : null,
    );
    const [showQuote, setShowQuote] = useState(true);

    useEffect(() => {
        if (!Array.isArray(quotes) || quotes.length === 0) {
            return;
        }

        const interval = setInterval(() => {
            setShowQuote(false);
            setTimeout(() => {
                setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
                setShowQuote(true);
            }, 500);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Box
                sx={{
                    right: 0,
                    width: 1,
                    bottom: 0,
                    height: 1,
                    zIndex: 9998,
                    display: "flex",
                    flexDirection: "column",
                    position: "fixed",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "background.default",
                    gap: 8,
                    ...sx,
                }}
                {...other}
            >
                <div className="flex justify-center items-center">
                    <m.div
                        animate={{
                            scale: [1, 0.9, 0.9, 1, 1],
                            opacity: [1, 0.48, 0.48, 1, 1],
                        }}
                        transition={{
                            duration: 2,
                            ease: "easeInOut",
                            repeatDelay: 1,
                            repeat: Infinity,
                        }}
                    >
                        <Favicon disabledLink sx={{ width: 64, height: 64 }} />
                    </m.div>

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1.6, 1, 1, 1.6, 1.6],
                            rotate: [270, 0, 0, 270, 270],
                            opacity: [0.25, 1, 1, 1, 0.25],
                            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                        }}
                        transition={{
                            ease: "linear",
                            duration: 3.2,
                            repeat: Infinity,
                        }}
                        sx={{
                            width: 100,
                            height: 100,
                            position: "absolute",
                            border: (theme) =>
                                `solid 3px ${alpha(
                                    theme.palette.primary.dark,
                                    0.24,
                                )}`,
                        }}
                    />

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1, 1.2, 1.2, 1, 1],
                            rotate: [0, 270, 270, 0, 0],
                            opacity: [1, 0.25, 0.25, 0.25, 1],
                            borderRadius: ["25%", "25%", "50%", "50%", "25%"],
                        }}
                        transition={{
                            ease: "linear",
                            duration: 3.2,
                            repeat: Infinity,
                        }}
                        sx={{
                            width: 120,
                            height: 120,
                            position: "absolute",
                            border: (theme) =>
                                `solid 8px ${alpha(
                                    theme.palette.primary.dark,
                                    0.24,
                                )}`,
                        }}
                    />
                </div>
                <AnimatePresence mode="wait">
                    {showQuote && quote && (
                        <m.div
                            key={quote}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Typography variant="h3" align="center">
                                {t(quote)}
                            </Typography>
                        </m.div>
                    )}
                </AnimatePresence>
            </Box>
        </>
    );
}

SplashScreen.propTypes = {
    sx: PropTypes.object,
};
