import { useContext } from "react";
import { GoogleMapsPlacesContext } from "./context";

/**
 * @typedef {Object} PlaceData
 * @property {string} city
 * @property {string} country
 * @property {number} latitude
 * @property {number} longitude
 *
 *
 * @typedef {Object} GoogleMapsPlacesProps
 * @property {boolean} isLoaded
 * @property {(input: string) => Promise<window.google.maps.places.AutocompletePrediction[]>} fetchPlaceSuggestions
 * @property {(placeId: string) => Promise<PlaceData>} getPlaceById
 */
/**
 *
 * @returns {GoogleMapsPlacesProps}
 */

export function useGoogleMapsPlacesContext() {
    const context = useContext(GoogleMapsPlacesContext);
    if (!context)
        throw new Error(
            "useGoogleMapsPlacesContext must be use inside GoogleMapsPlacesProvider",
        );

    return context;
}
